gsap.registerPlugin(ScrollTrigger);

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        $('body').addClass('ios');
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        $('body').addClass('ios');
        return "iOS";
    }

    return "unknown";
}
const ScrollLottie = (obj) => {
    lottie.loadAnimation({
        container: document.querySelector(obj.target),
        renderer: 'svg',
        loop: false,
        autoplay: obj.bool,
        path: obj.path
    });
}

function GallerySlider() {

    var slider = new Swiper(".swiper-container", {
        slidesPerView: 1.8,
        spaceBetween: 20,
        centeredSlides: true,
        mousewheel: true,
        lazy: true,
        watchSlidesVisibility: true,
        loadPrevNext: true,
        loadPrevNextAmount: 2,
        preloadImages: false,
        breakpoints: {
            880: {
                slidesPerView: 'auto',
                spaceBetween: 160,
            },
        }
    })

}

function scrollTriggerMobile() {

    let sections = gsap.utils.toArray(".panel");
    let elements = gsap.utils.toArray(document.querySelectorAll(".panel .panel_item"));
    const element_ratio = Math.round(window.innerHeight / sections[0].getBoundingClientRect().width);
    console.log(window.innerWidth / sections[0].getBoundingClientRect().width)
    const filter_element = elements.filter((element, index) => {
        return (index + 1 <= element_ratio)
    })

    const filter_element_none = elements.filter((element, index) => {
        return (index + 1 > element_ratio)
    })

    filter_element.forEach((element, index) => {
        const childNode = element.querySelectorAll('.panel_group_mobile');

        setTimeout(function () {
            gsap.to(childNode, {
                opacity: 1,
                duration: 1,
            })
            element.parentNode.classList.add('added');
            ScrollLottie({
                target: `#animationWindow-${index}`,
                path: `json/frame${index}.json`,
                bool: true,
            })
        }, index * 1500)

    });


    // const boxes = gsap.utils.toArray('.panel_group_mobile');
    filter_element_none.forEach((box, index) => {
        const childNode = box.querySelectorAll('.panel_group_mobile');
        const childNode_social = box.querySelectorAll('.card-social ');
        gsap.to(childNode, {
            opacity: 1,
            // duration: 3,
            scrollTrigger: {
                trigger: box,
                scrub: 1,
                start: "10% 85%",
                end: "top 50%",
                toggleActions: "play complete reverse reset",
                // markers: true,
                onEnter: ({progress, direction, isActive}) => {
                    if (box.parentNode.classList.contains('added')) {
                        return;
                    }
                    box.parentNode.classList.add('added');
                    ScrollLottie({
                        target: `#animationWindow-${index + filter_element.length}`,
                        path: `json/frame${index + filter_element.length}.json`,
                        bool: true,
                    })
                }
            }
        })
        gsap.to(childNode_social, {
            opacity: 1,
            // duration: 3,
            scrollTrigger: {
                trigger: box,
                scrub: 1,
                start: "10% 85%",
                end: "top 50%",
                toggleActions: "play complete reverse reset",
                // markers: true,
            }
        });
    });



}

function scrollTriggerDesktop() {
    let sections = gsap.utils.toArray(".panel");
    let container = document.querySelector(".js-scroll-home");
    let elements = gsap.utils.toArray(document.querySelectorAll(".panel .panel_item"));
    const element_ratio = Math.round(window.innerWidth / sections[0].getBoundingClientRect().width);
    console.log(window.innerWidth / sections[0].getBoundingClientRect().width)
    const filter_element = elements.filter((element, index) => {
        return (index + 1 <= element_ratio)
    })

    const filter_element_none = elements.filter((element, index) => {
        return (index + 1 > element_ratio)
    })

    filter_element.forEach((element, index) => {

        setTimeout(function () {
            gsap.to(element, {
                opacity: 1,
                duration: 2,
            })
            element.parentNode.classList.add('added');
            ScrollLottie({
                target: `#animationWindow-${index}`,
                path: `json/frame${index}.json`,
                bool: true,
            })
        }, index * 1500)

    });

    setTimeout(function () {
        let timeline = gsap.timeline();
        timeline.to(sections, {
            x: () =>
                -(container.scrollWidth - document.documentElement.clientWidth) + "px",
            ease: "none",
            scrollTrigger: {
                pin: true,
                scrub: 3,
                overwrite: "auto",
                trigger: container,
                end: () => container.scrollWidth - document.documentElement.clientWidth
            }
        });
    }, (filter_element.length) * 1500)

    let scrollTriggerTimeline = gsap.timeline();

    filter_element_none.forEach((element, index) => {
        scrollTriggerTimeline.to(element, {
            opacity: 1,
            overwrite: "auto",
            duration: 1.5,
            delay: 1,
            scrollTrigger: {
                scrub: 1,
                start: () => element.parentNode.offsetLeft - window.innerWidth + element.parentNode.getBoundingClientRect().width / 1.5,
                end: () =>
                    element.parentNode.offsetLeft -
                    window.innerWidth +
                    element.parentNode.getBoundingClientRect().width,
                onEnter: () => {
                    if (element.parentNode.classList.contains('added')) {
                        return;
                    }
                    element.parentNode.classList.add('added');
                    ScrollLottie({
                        target: `#animationWindow-${index + filter_element.length}`,
                        path: `json/frame${index + filter_element.length}.json`,
                        bool: true,
                    })
                }
            }
        });

    });
}

$(document).ready(function(){
    getMobileOperatingSystem()
})
$(window).on('load', function () {

    window.addEventListener("orientationchange", function (event) {
        if (!$('.js-gallery').hasClass('active')) {
            window.location.reload();
        }

    });

    gsap.set('.js-gallery', {pointerEvents: 'none'})
    const tl = gsap.timeline();
    GallerySlider();
    $('.js-gallery-1').on('click', function (e) {
        e.preventDefault();
        $('body').toggleClass('overflow-hidden');
        $('.js-gallery').addClass('active');
        tl.to('.js-scroll-home', {
            duration: 1,
            opacity: 0,
        });
        tl.to('.js-gallery', {
            duration: 1,
            opacity: 1,
        });
        gsap.set('.js-gallery', {pointerEvents: 'all'})
        gsap.set('.js-scroll-home', {pointerEvents: 'none'})
    })
    $('.js-gallery-back').on('click', function (e) {
        e.preventDefault();
        $('body').toggleClass('overflow-hidden');
        tl.to('.js-gallery', {
            opacity: 0,
            duration: 1,
        });
        tl.to('.js-scroll-home', {
            opacity: 1,
            duration: 1,
        });

        gsap.set('.js-gallery', {pointerEvents: 'none'})
        gsap.set('.js-scroll-home', {pointerEvents: 'all'})
        $('.js-gallery').removeClass('active');
    })
    if ($(window).width() > 880) {
        scrollTriggerDesktop();

        var resizeTimer;
        $(window).on('resize', function (e) {
            clearTimeout(resizeTimer);
            if (!$('.js-gallery').hasClass('active')) {
                resizeTimer = setTimeout(function () {
                    // Run code here, resizing has "stopped"
                    window.location.reload();

                }, 250);
            }


        });

    } else {
        scrollTriggerMobile();
    }
});
